import React, {useContext} from 'react';
import {DarkModeContext} from './context/DarkModeContext';

function Content() {
    const {darkMode} = useContext(DarkModeContext);
    const contentProvider = () => {
        if (darkMode) {
            return <p>it's a perfect night for le<span>t</span>ting go</p>
        }
        return <p>it's a perfect day for letting go</p>
    }
    return (
        <div className={darkMode ? `Content Content-dark` : `Content`}>
           {contentProvider()}
        </div>
    )
}

export default Content
