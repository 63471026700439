import React, {createContext, useState, useEffect} from 'react';

const DarkModeContext = createContext();

let now = new Date();
let hours = now.getHours();
const nowTime = hours;
const dayTime = ( (nowTime <= 18) && (nowTime >= 9 ) ? true : false);

function DarkModeProvider(props){
    const [darkMode, setDarkMode] = useState(dayTime ? false : true);

//    const [data, setData] = useState(null);
    // const [loading, setLoading] = useState(true);
    // const [error, setError] = useState(null);

    useEffect(() => {
			const getCurentData = () => {
				 fetch(`https://zenkin.ru/api/api.php`)
        	.then((response) => response.json())
        	.then((actualData) => {
//            	console.log(actualData);
//            	setData(actualData);
            	//console.log(actualData["location"]["country_name"]);
							//const current_time = actualData["location"]["current_time"].substr(0, 4);
							// let current_time = new Date();
							// console.log(actualData['current_time']);

							if ( (actualData['current_time'] < actualData["sunset"]) && (actualData['current_time'] > actualData["sunrise"]) ) {
								console.log('даёшь Cолнца!');
								setDarkMode(false);
							} else {
								setDarkMode(true);
								console.log('темно, однако');
							}
        	})
        	.catch((err) => {
         		console.log(err.message);
        	});
				}

				getCurentData();

				const interval = setInterval(() => {
					getCurentData();
				}, 60000);

				return () => clearInterval(interval);

      	}, []);

    const toggleDarkMode = (dark) => {
        setDarkMode(!darkMode);
    };

    return (
        <div>  
            <DarkModeContext.Provider value={{darkMode, toggleDarkMode}}>
                {props.children}
            </DarkModeContext.Provider>
        </div>
    )
};

export {DarkModeContext, DarkModeProvider};