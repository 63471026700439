import React, {useContext} from 'react';
import Content from './Content';
import {DarkModeContext} from './context/DarkModeContext';

function Container() {
    const {darkMode} = useContext(DarkModeContext);
    console.log(darkMode);
    return (
        <div className={darkMode ? `Container Container-dark` : `Container Container-light`}>
          <Content/>
        </div>
    )
}

export default Container
